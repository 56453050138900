export const API_BASE_URL = "https://api.cruizewatersport.ai";

export const MEDIA_URL = "https://cruize-data-storage.s3.us-east-2.amazonaws.com";
export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/v1/admin/auth/login`,
    me: `${API_BASE_URL}/api/v1/admin/auth/profile`,
    forgot: `${API_BASE_URL}/api/v1/admin/auth/forgot-password`,
    verifotp: `${API_BASE_URL}/api/v1/admin/auth/otp-verification`,
    reset: `${API_BASE_URL}/api/v1/admin/auth/reset-password`,
    resend: `${API_BASE_URL}/api/v1/admin/auth/resend-otp`
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/admin/auth/dashboard`,
  },
  Content: {
    list: `${API_BASE_URL}/api/v1/admin/contentmanagement`,
    edit: `${API_BASE_URL}/api/v1/admin/contentmanagement`,
  },
  Business: {
    list: `${API_BASE_URL}/api/v1/admin/business`,
    create: `${API_BASE_URL}/api/v1/admin/business`,
    edit: (id) => `${API_BASE_URL}/api/v1/admin/business/${id}`,
    delete: (id) => `${API_BASE_URL}/api/v1/admin/business/${id}`,
    customer: `${API_BASE_URL}/api/v1/auth/user`
  },
  Career: {
    list: `${API_BASE_URL}/api/v1/admin/careerdetails`,
    create: `${API_BASE_URL}/api/v1/admin/careerdetails`,
    edit: (id) => `${API_BASE_URL}/api/v1/admin/careerdetails/${id}`,
    delete: (id) => `${API_BASE_URL}/api/v1/admin/careerdetails/${id}`,
  },
  CATEGORY: {
    list: `${API_BASE_URL}/api/v1/admin/categories`,
    create: `${API_BASE_URL}/api/v1/admin/categories`,
    edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/${id}`,
    delete: (id) => `${API_BASE_URL}/api/v1/admin/categories/${id}`,
  },
  SUB_CATEGORY: {
    list: (id) => `${API_BASE_URL}/api/v1/admin/categories/${id}/categoryTypes`,
    create: (id) =>`${API_BASE_URL}/api/v1/admin/categories/${id}/categoryTypes`,
    edit: (id,cid) => `${API_BASE_URL}/api/v1/admin/categories/${id}/categorytypes/${cid}`,
    delete: (id,cid) => `${API_BASE_URL}/api/v1/admin/categories/${id}/categorytypes/${cid}`,
  },
  PROPERTY: {
    list: (id) => `${API_BASE_URL}/api/v1/admin/property/getAllOwnerProperty/${id}`,
    create: (id) =>`${API_BASE_URL}/api/v1/admin/property/addProperty/${id}`,
    edit: (id) => `${API_BASE_URL}/api/v1/admin/property/updateProperties/${id}`,
    delete: (id,cid) => `${API_BASE_URL}/api/v1/admin/property/deleteProperty/${id}`,
  },
  Charges: {
    list: `${API_BASE_URL}/api/v1/admin/property/getAddtionalcharges`,
    edit: `${API_BASE_URL}/api/v1/admin/property/upsertAddtionalcharges`,
  },
  Service: {
    list: `${API_BASE_URL}/api/v1/admin/service`,
    create: `${API_BASE_URL}/api/v1/admin/service`,
    edit: (id) => `${API_BASE_URL}/api/v1/admin/service/${id}`,
    delete: (id) => `${API_BASE_URL}/api/v1/admin/service/${id}`,
  },
  Sub_Service: {
    list: (id) => `${API_BASE_URL}/api/v1/auth/sub-service/user/${id}`,
    create: (id) => `${API_BASE_URL}/api/v1/auth/sub-service/${id}`,
  },
  GET_REGION: {
    country: `https://api.countrystatecity.in/v1/countries`,
  },
};
