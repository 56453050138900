// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import LockIcon from "@mui/icons-material/Lock";
import CategoryIcon from "@mui/icons-material/Category";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
const navigation = () => {
  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/dashboard",
    },
    // {
    //   sectionTitle: "Content-Management",
    // },
    // {
    //   title: "Website",
    //   icon: PublicIcon,
    //   children: [
    //     {
    //       title: "Content",
    //       icon: LockIcon,
    //       path: "/content",
    //     },
    //   ],
    // },
    {
      sectionTitle: "Knowledge Base",
    },
    {
      title: "Users",
      icon: Diversity3Icon,
      children: [
        {
          title: "Business Users",
          icon: PersonIcon,
          path: "/business",
        },
        {
          title: "Customer",
          icon: PeopleIcon,
          path: "/customer",
        },
      ],
    },

    {
      title: "Category",
      icon: CategoryIcon,
      path: "/category",
    },
    {
      title: "Website Content",
      icon: LockIcon,
      path: "/content",
    },
    {
      title: "Career",
      icon: GroupsIcon,
      path: "/career",
    },
    // {
    //   title: "Category",
    //   icon: CategoryIcon,
    //   children: [
    //     {
    //       title: "Category",
    //       icon: LockIcon,
    //       path: "/category",
    //     },
    //     {
    //       title: "Sub Category",
    //       icon: TaskIcon,
    //       path: "/sub-category",
    //     },
    //     {
    //       title: "Property",
    //       icon: SupervisedUserCircleIcon,
    //       path: "/property",
    //     }
    //   ],
    // },
    {
      title: "Settings",
      icon: SettingsIcon,
      children: [
        {
          title: "Tax Management",
          icon:  CategoryIcon,
          path: "/tax-management",
        },
      ],
    },
    {
      title: "Staff Management",
      icon: PeopleAltIcon,
      children: [
        {
          title: "Staff Category",
          icon:  PeopleAltIcon,
          path: "/staff-category",
        },
      ],
    },
  ];
};

export default navigation;
