// ** MUI Import
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CruzLogo from "../../../../src/assets/images/Logo.png";

const FallbackSpinner = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img src={CruzLogo} alt="" style={{ height: "55px", width: "132px" }} />
      <CircularProgress disableShrink sx={{ mt: 6 }} />
    </Box>
  );
};

export default FallbackSpinner;
